import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

// NOTE: this will attach CSRF input to given form element
// as some forms are not rendered via turbo on the backend side
// it silently ignores CSRF token in forms
// it still works for all turbo request as it seems like turbo attaches
// CSRF token from the meta tags automagically
// https://github.com/hotwired/turbo-rails/issues/243
export default class extends Controller {
  private csrfParam = Rails.csrfParam();

  connect() {
    let form = this.element;

    if (form.querySelector(`input[name='${this.csrfParam}']`) === null) {
      form.appendChild(this.authenticityTokenInput);
    }
  }

  get authenticityTokenInput() {
    const input = document.createElement("input");

    input.type = "hidden";
    input.name = this.csrfParam;
    input.autocomplete = "off";
    input.value = Rails.csrfToken();

    return input;
  }
}
