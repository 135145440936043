import Rails from "@rails/ujs";
import "core-js";
import "@slightlyoff/lite-vimeo";
import "@hotwired/turbo-rails";

// expose jquery for all those small async rails snippets
global.$ = global.jQuery = require("jquery");

// packages
require("jquery-colorbox/jquery.colorbox");
require("jquery-colorbox/i18n/jquery.colorbox-de");

require("bootstrap/js/dist/scrollspy");
require("bootstrap/js/dist/modal");
require("bootstrap/js/dist/tab");

// custom, require everything one-by-one for now
require("../javascripts/lib/image-preolader");
require("../javascripts/lib/scroll-to-me");
require("../javascripts/lib/range-slider");
require("../javascripts/lib/chip-checkbox");
require("../javascripts/job/proposal");
require("../javascripts/job/proposals");

require("../javascripts/bootstrap-components/cost-estimations");
require("../javascripts/bootstrap-components/global-notifications");
require("../javascripts/bootstrap-components/new-job");
require("../javascripts/bootstrap-components/show-hide-details");
require("../javascripts/bootstrap-components/verification-icon");
require("../javascripts/controllers");
require("./sentry");

Rails.start();
Turbo.session.drive = false;
Turbo.config.forms.mode = "optin";
