import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form", "submit", "textarea"];

  private formTarget: HTMLFormElement;
  private submitTarget: HTMLButtonElement;
  private textareaTarget: HTMLTextAreaElement;
  private hasTextareaTarget: boolean;

  connect() {
    if (this.hasTextareaTarget) {
      this.textareaTarget.focus();
    }
  }

  onEnter(event: KeyboardEvent) {
    event.preventDefault();

    // use Turbo's Form submission
    if (this.formTarget.reportValidity()) {
      this.submitTarget.click();
    }
  }

  onInput(event: Event) {
    this.submitTarget.disabled = (event.target as HTMLInputElement).value.trim() === "";
  }
}
