const clickedValue = (event) => {
  debugger
  switch (event.target.tagName) {
    case "INPUT":
      return event.target.value;
    case "LABEL":
      return event.target.parentNode.children[0].value;
    default:
      return event.target.children[0].value;
  }
};

export default clickedValue;
