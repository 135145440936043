import { Controller } from "stimulus";
import { Fetcher } from "../fetcher";
import { serverErrorNotification } from "../components/notification";
import clickedValue from "../lib/value_extractor";

export default class extends Controller {
  static targets = ["submitButton"];

  static values = {
    default: String,
    url: String,
    wait: Number,
  };

  connect() {
    this.fetcher = new Fetcher();

    addEventListener("turbo:before-redirect", () => {
      // We select the default value if the job failed the conditions
      this.post(this.defaultValue);
    });

    setTimeout(() => {
      // We render the button when we already got an update
      if (!this.hasSubmitButtonTarget) {
        this.post(this.defaultValue);
      }
    }, this.waitValue);
  }

  updateMatchingMode(event) {
    event.preventDefault();

    this.post(clickedValue(event));
  }

  post(value) {
    this.fetcher
      .request(this.urlValue, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.formData(value)),
      })
      .then((result) => {
        window.location = result.location;
      })
      .catch(() => {
        serverErrorNotification();
      });
  }

  matchingMode(value) {
    return value !== "nil" ? value : null;
  }

  formData(value) {
    const urlParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlParams);
    params.matching_mode = this.matchingMode(value);

    return params;
  }
}
