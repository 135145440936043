import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();

application.handleError = (error) => {
  throw error;
};

const context = require.context("./controllers", true, /(\.js|\.ts)$/);
const contextComponents = require.context(
  "../../components",
  true,
  /_controller(\.js|\.ts)$/,
);

application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(contextComponents),
  ),
);
