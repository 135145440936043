import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "submit"];

  private submitTarget: HTMLButtonElement;
  private checkboxTargets: HTMLInputElement[];

  connect() {
    this.checkboxChanged()
  }

  checkboxChanged() {
    this.submitTarget.disabled = !this.isChecked;
  }

  get isChecked() {
    return this.checkboxTargets.some(checkbox => checkbox.checked);
  }
}
