import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["submit"];

  static values = {
    nextPageDelay: Number,
  }

  private nextPageDelayValue: number;
  private submitTarget: HTMLButtonElement;

  onSelect(event) {
    if (event.currentTarget.children[0] && event.currentTarget.children[0].type === "radio") {
      event.currentTarget.children[0].click();
      this.submitTarget.disabled = false;
    }

    setTimeout(() => this.submitTarget.click(), this.nextPageDelayValue)
  }
}
