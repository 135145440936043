import { Controller } from "stimulus";
import * as L from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    try {
      L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
      const latitude = parseFloat(this.data.get("latitude"));
      const longitude = parseFloat(this.data.get("longitude"));
      const icon = this.data.get("marker-icon");
      const shadow = this.data.get("marker-shadow");
      const center = [latitude, longitude];
      const map = L.map(this.containerTarget, {
        gestureHandling: true,
        gestureHandlingOptions: {
          text: {
            touch: "Use two fingers to move the map",
            scroll: "Use ctrl + scroll to zoom the map",
            scrollMac: "Use \u2318 + scroll to zoom the map",
          },
        },
      }).setView(center, 12);

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 25,
      }).addTo(map);

      const markerIcon = L.icon({
        iconSize: [25, 41],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40],
        iconUrl: icon,
        shadowUrl: shadow,
      });
      L.marker(center, { icon: markerIcon }).addTo(map);
    } catch (error) {
      // do not crash whole site upon invalid map data (on dev/testing we might have a lot of junk)
      console.error(error);
    }
  }
}
