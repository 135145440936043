import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "scopeScope",
  ];

  static values = {
    data: Object,
  }

  dataValue: any;
  scopeScopeTargets: HTMLInputElement[];

  connect() {
    this.update();
  }

  update() {
    const scope = this.getScopeScope();
    const averageEstimate = this.dataValue.scope[scope];

    document.getElementById("average_estimate").innerText = averageEstimate;
  }

  getScopeScope(): string {
    return this.scopeScopeTargets
      .find((option) => option.checked)
      .value
  }
}
