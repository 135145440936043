import { Controller } from "stimulus";
import Swiper from "swiper";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

const DEFAULT_CONFIG = {
  modules: [Navigation, Pagination],
  slidesPerView: 1,
  spaceBetween: 10,
  speed: 1000,
  loop: true,
  autoplay: {
    delay: 5000,
  },
  breakpoints: {
    768: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 3,
    },
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
    dynamicBullets: "true",
    dynamicMainBullets: 7,
  },
};
export default class extends Controller {
  static targets = ["slide"];

  connect() {
    Swiper.use([Navigation, Pagination, Autoplay]);

    this.swiper = new Swiper(".swiper", DEFAULT_CONFIG);
    this.swiper.autoplay.start();
    this.swiper.loopDestroy();
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = undefined;
  }
}
