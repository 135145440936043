import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "electrifyContainer",
    "electrifyScope",
    "electrifySwitch",
    "installReplaceContainer",
    "installReplaceScope",
    "installReplaceSwitch",
    "repairContainer",
    "repairScope",
    "repairSwitch",
  ];

  static values = {
    data: Object,
  }

  dataValue: any;
  electrifyContainerTarget: HTMLDivElement;
  electrifyScopeTargets: HTMLInputElement[];
  electrifySwitchTarget: HTMLInputElement;
  installReplaceContainerTarget: HTMLDivElement;
  installReplaceScopeTargets: HTMLInputElement[];
  installReplaceSwitchTarget: HTMLInputElement;
  repairContainerTarget: HTMLDivElement;
  repairScopeTargets: HTMLInputElement[];
  repairSwitchTarget: HTMLInputElement;

  connect() {
    this.update();
  }

  update() {
    const electrifyAmount = this.electrifySwitchTarget.checked ? this.getElectrifyValue() : 0;
    const installReplaceAmount = this.installReplaceSwitchTarget.checked ? this.getInstallReplaceValue() : 0;
    const repairAmount = this.repairSwitchTarget.checked ? this.getRepairValue() : 0;

    let averageEstimate = electrifyAmount + installReplaceAmount + repairAmount;

    document.getElementById("average_estimate").innerText = averageEstimate.toString();
  }

  switchElectrify() {
    if (this.electrifySwitchTarget.checked) {
      this.electrifyContainerTarget.classList.remove("d-none");
    } else {
      this.electrifyContainerTarget.classList.add("d-none");
    }
  }

  switchInstallReplace() {
    if (this.installReplaceSwitchTarget.checked) {
      this.installReplaceContainerTarget.classList.remove("d-none");
    } else {
      this.installReplaceContainerTarget.classList.add("d-none");
    }
  }

  switchRepair() {
    if (this.repairSwitchTarget.checked) {
      this.repairContainerTarget.classList.remove("d-none");
    } else {
      this.repairContainerTarget.classList.add("d-none");
    }
  }

  calculate(loadCost, distance, finalCleaning) {
    let distanceCost = this.dataValue.distance.min_cost + (distance - this.dataValue.distance.slider[0]) * this.dataValue.distance.cost_per_km;
    let finalCleaningCost = finalCleaning == false ? 0 : this.dataValue.final_cleaning.cost[finalCleaning];
    return loadCost + distanceCost + finalCleaningCost;
  }

  getElectrifyValue(): number {
    const option = this.electrifyScopeTargets
      .find((option) => option.checked)
      .value

    return this.dataValue.electrify[option]
  }

  getInstallReplaceValue(): number {
    const option = this.installReplaceScopeTargets
      .find((option) => option.checked)
      .value

    return this.dataValue.install_replace[option]
  }

  getRepairValue(): number {
    const option = this.repairScopeTargets
      .find((option) => option.checked)
      .value

    return this.dataValue.repair[option]
  }
}
