import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["message"];

  private messageTarget: HTMLElement;
  private hasMessageTarget: boolean;

  connect() {
    document.addEventListener("turbo:submit-start", () => {
      if (!this.hasMessageTarget) {
        return;
      }

      const spinner = document.createElement("span");
      spinner.classList.add("spinner", "me-1");

      this.messageTarget.prepend(spinner);
    });
  }
}
